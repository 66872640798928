import {ExtendedWindow} from "./WindowHelper";
declare let window: ExtendedWindow;

export function translate(key: string, replace: Array<{key: string, value:string}> = null):string
{
    let text = window.translate(key);
    for (let i = 0; i < replace?.length; i++)
        text = text.replace(replace[i].key, replace[i].value);

    return text;
}
